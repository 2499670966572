// Update if you would like to force a build of this service without changing any logic
// Force CI build: 2025-02-07
import '../styles/globals.css';
import '../styles/nprogress.css';
import '../styles/animations.css';
import '../styles/emoji-picker-react.css';
import '../styles/headway.css';
import '../styles/puck.css';
import '../styles/shadcn.css';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';

import { ThemeProvider } from '@/components/ui/theme-provider';
import { TooltipProvider } from '@/components/ui/tooltip';
import { initializeDateFns } from '@/utilities/date';
import { initializeFirebase } from '@/utilities/firebase';
import { initializePolyfills } from '@/utilities/polyfills';
import { initializeProgressBar } from '@/utilities/progress-bar';
import { AnalyticsProvider, PageView } from '@packfleet/analytics';
import { FeatureFlagsProvider } from '@packfleet/feature-flags';
import { useAutoRefresh } from '@packfleet/hooks';
import { ErrorPage } from '../components/error/ErrorPage';
import IntercomProvider from '../components/intercom/IntercomProvider';
import { Toaster } from '../components/ui/toaster';
import { useApollo } from '../graphql/client';

initializeFirebase();
initializeProgressBar();
initializeDateFns();

initializePolyfills();

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const getLayout =
    Component.getLayout ?? ((page) => <PageView>{page}</PageView>);

  useAutoRefresh();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <AnalyticsProvider apiKey={process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? ''}>
        <FeatureFlagsProvider
          apiKey={process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT ?? ''}
        >
          <ApolloProvider client={apolloClient}>
            <IntercomProvider>
              <ThemeProvider
                attribute="class"
                // TODO: Enable system, add switch
                defaultTheme="light"
                enableSystem={false}
                disableTransitionOnChange
              >
                <TooltipProvider delayDuration={0}>
                  {getLayout(<Component {...pageProps} />)}
                </TooltipProvider>
                <Toaster />
              </ThemeProvider>
            </IntercomProvider>
          </ApolloProvider>
        </FeatureFlagsProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
